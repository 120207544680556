// @flow

import { useMemo, } from 'react';
import { useQuery, } from 'react-apollo';
import { ARTICLE_BODY_QUERY, ARTICLE_BODY_PRINT_QUERY, } from '@haaretz/graphql';
import useArticleId from './useArticleId';
import usePrint from './usePrint';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

export const flatBody = (bodyRaw: Object) => bodyRaw.reduce((acc, element) => {
  if (element.inputTemplate === 'Enhancement') {
    const sharedData = {
      preventRender: element.preventRender,
      excludePlatform: element.excludePlatform,
      excludeUserTypes: element.excludeUserTypes,
    };


    const biData = {
      actionId: element.actionId,
      campaignDetails: element.campaignDetails,
      campaignName: element.campaignName,
      featureType: element.featureType,
      feature: element.feature,
      includeData: element.includeData,
    };

    element.items.forEach(item => {
      if (item.inputTemplate === 'EmbedRichTextElement') {
        if (item.item) {
          acc.push({
            ...item.item,
            position: item.placement,
            ...sharedData,
          });
        }
      }
      else if (item.inputTemplate === 'ImageRichTextElement') {
        if (item.image) {
          acc.push({
            ...item.image,
            position: item.placement,
            viewMode: item.aspect,
            ...sharedData,
            ...biData,
          });
        }
      }
      else if (item.inputTemplate === 'InfographicRichTextElement') {
        if (item.item) {
          acc.push({
            ...item.item,
            ...sharedData,
          });
        }
      }
      else if (item.inputTemplate === 'InteractiveRichTextElement') {
        if (item.content) {
          if (item.content.inputTemplate === 'TenRecipes') {
            acc.push({
              ...item.content,
              ...sharedData,
            });
          }
          else {
            acc.push({
              ...item,
              position: item.placement,
              ...sharedData,
            });
          }
        }
      }
      else if (item.inputTemplate === 'RegistrationRichTextElement') {
        acc.push({
          ...item,
          ...sharedData,
          renderFrequency: item.frequency,
        });
      }
      else if (item.inputTemplate === 'StaticElementRichTextElement') {
        acc.push({
          inputTemplate: 'InteractiveRichTextElement',
          content: {
            ...item,
            ...sharedData,
            position: item.placement,
          },
        });
      }
      else {
        acc.push({
          ...item,
          ...sharedData,
          ...biData,
        });
      }
    });
  }
  else {
    acc.push(element);
  }

  return acc;
}, []);

export default function useArticleBodyData() {
  const articleId = useArticleId();
  const { isPrintByArticleType, } = usePrint();

  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, loading, error, } = useQuery(
    isPrintByArticleType ? ARTICLE_BODY_PRINT_QUERY : ARTICLE_BODY_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: queryVariables,
    });

  const bodyRaw = data?.Page?.slots?.article?.body || [];

  const body = useMemo(() => flatBody(bodyRaw), [ bodyRaw, ]);

  if (loading) return null;
  if (error) {
    console.error('Article Body error', error);
    return null;
  }

  if (!data) return null;


  return body;
}
